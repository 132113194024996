@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:700');

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  padding-top: 80px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}